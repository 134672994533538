// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ClearCache from 'react-clear-cache';

// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store,persistor } from "./redux/storeConfig/store";
import ReduxToastr from 'react-redux-toastr'

import "font-awesome/css/font-awesome.min.css";



import 'react-web-vector-icons/fonts';

import "./index.scss";

import Spinner from "./components/spinner/spinner";

import { PersistGate } from 'redux-persist/integration/react'



const LazyApp = lazy(() => import("./app/app"));

ReactDOM.render(
   <Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
       <ClearCache>
           {({ isLatestVersion, emptyCacheStorage }) => {
               console.log("LATEST VERSION",isLatestVersion)
               if(!isLatestVersion)
               {
                   emptyCacheStorage()
               }
               return(<div></div>)
           }}
       </ClearCache>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-left"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick/>
      </Suspense>
      </PersistGate>
   </Provider>,
   document.getElementById("root")
);
registerServiceWorker();
