// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";


//user 
export const CHANGE_LOGIN = "CHANGE_LOGIN"
export const CHANGE_USER_TYPE = "CHANGE_USER_TYPE"